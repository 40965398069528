.map-wrapper .leaflet-container {
    border-radius: 25px;
}
.leaflet-container .leaflet-marker-pane .map-user-image, 
.custom-popup .map-user-image{
    width: 70px;
    height: 70px;
    border-radius: 100%;
    border: 3px solid #F7ADFF;
}

.leaflet-container .leaflet-marker-pane .map-user-image-for-community, 
.custom-popup .map-user-image-for-community{
    width: 70px;
    height: 70px;
    border-radius: 100%;
}

.leaflet-container .leaflet-marker-pane .map-user-image2, 
.custom-popup .map-user-image2{
    width: 70px;
    height: 70px;
    border-radius: 100%;
    border: 3px solid #00AD7A;
}

.map-card-wrapper {
    width: 220px;
    background: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    box-shadow: 0px 5px 30px rgba(101,101,101,0.5);
}
.popup-heading {
    font-family: "Inter Tight";
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 1px;
    color: #000000;
}
.popup-heading-para {
    color: #b9b9b9;
    font-family: "Inter Tight";
    font-size: 12px;
    font-weight: 500;
    margin: 0 !important;
    line-height: 1.2em;
}
.map-card-txt {
    padding: 10px;
}
.leaflet-popup-content-wrapper {
    border-radius: 38px;
}
.leaflet-popup-content {
    margin: 0;
}
.popup-head {
    display: flex;
    align-items:flex-start;
}
.popup-head-content {
    padding: 20px 8px 0 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.members-image-wrapper img {
    width: 25px;
    height: 25px;
    border-radius: 100%;
}
.popup-discription h5 {
    color: #b9b9b9;
    font-family: "Inter Tight";
    font-size: 12px;
    font-weight: 500;
}
.popup-discription h4 {
    font-family: "Inter Tight";
    font-size: 12px;
    font-weight: 700;
}
.popup-discription {
    padding: 20px;
}
.leaflet-container a.leaflet-popup-close-button {
    display: none;
}
.leaflet-div-icon {
    background: transparent !important;
    border: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
    max-width: none !important;
    max-height: none !important;
    width: 60px;
    height: 60px;
    padding: 0;
}

.map-card-wrapper-community {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    padding: 10px;
    text-align: center;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 30px rgba(101,101,101,0.5);
}


.blue{
    background: #3652D9;
  }


  .center {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
  }
  
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
  }
  
  .pulse {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  
  
  .master{
    color:black;
    float:right;
  }
  .shared-mingle-handshake {
    display: flex;
    align-items: center;
    gap: 8px;
}
.shard-group-icon {
  position: absolute;
  top: -36px;
  left: -22px !important;
  background: #fff;
  left: 0;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #c3c3c3; */
}
.shared-mingle-group-icon {
  position: relative;
  width: 100%;
}
  @media (max-width:900px){
    .master{
      display:none
    }
  }


  .leaflet-marker-spiderfy {
    background-color: #ff0000; /* Set the background color to red */
    border: 2px solid #00ff00; /* Set a green border */
    border-radius: 50%; /* Make it a circle */
    width: 30px; /* Set the width */
    height: 30px; /* Set the height */
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff; /* Set text color to white */
    cursor: pointer; /* Add a pointer cursor */
  }
  
  /* Style the spiderfied cluster markers on hover */
  .leaflet-marker-spiderfy:hover {
    background-color: #00ff00; /* Change the background color on hover to green */
    border: 2px solid #ff0000; /* Change the border color on hover to red */
    color: #000000; /* Change text color on hover to black */
  }
  .home-icon {
    width: 50px;
    height: 50px;
}
path.pin-color {
    fill: #61abb6;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup{
    bottom: -1px !important;
    left: -132px !important;
}
.custom-popup .card {
    display: flex;
    align-items: stretch;
    gap: 10px;
    padding: 10px;
    margin: 0;
}
.custom-popup .card img {
    width: 70px;
    height: 70px;
    object-fit: cover;
}
.custom-popup .card-content {
    padding: 0;
    display: flex;
    align-items: flex-start;
    gap: 4px;
    flex-direction: column;
    justify-content: center;
}
.leaflet-popup-content p {
    margin: 0 0 5px;
}