.auth-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 81px);
  background-image: url(../../../assets/images/hero.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  padding: 15px;
}
.input-btn {
  width: 100%;
}
.forget-btn p {
  text-align: right;
  margin-top: 4px;
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.auth-form h1 {
  color: #0098ff;
  font-family: "nyght_serifregular";
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 24px;
}
.term_condition input {
  position: absolute;
  left: 0px;
  top: 5px;
}
.term_condition {
  position: relative;
}
.term_condition label {
  padding-left: 20px;
}
.term_condition label button {
  display: inline-block;
}
.auth-container form label {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: inline-block;
}
.term_condition label button {
  background: transparent;
  border: none;
  text-decoration: underline;
  font-size: 16px;
}
.term_condition {
  margin-bottom: 15px;
}
.auth-container {
  padding: 24px;
  width: 100%;
  max-width: 360px;
  border-radius: 16px;
  background: #f7f6f3;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.auth-form .auth-container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.auth-form .input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
}
.password-eye-wrapper{
  position: absolute;
  right: 8px;
  top: 34px;
  cursor: pointer;
}
.password-eye-wrapper svg{
  width: 16px;
  height: 14px;
}
.auth-form .input-container input {
  padding: 8px;
  min-height: 40px;
  border-radius: 4px;
  border: 1px solid #dbd9d7;
  background: #fff;
  outline: none;
  margin-top: 5px;
  width: 100%;
  color: #868179;
}
.auth-form .input-container input:-internal-autofill-selected {
  color: #868179 !important;
}
.auth-form .input-container input::placeholder{
  color: #868179 !important;
}
.auth-form .input-container input[type="password" i]{
padding-right: 28px;
}



.auth-form .input-container .error {
  color: red;
  font-size: 12px;
}

.google_login_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.google_login_button .google-button {
  border-radius: 22px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.google-button svg {
  width: 40px;
  height: 40px;
}
.auth-form form .join-btn {
  padding: 16px 8px;
  font-family: Inter;
  font-weight: 600;
  line-height: 105%;
  color: #fff;
  font-size: 14px;
  width: 100%;
  letter-spacing: 1px;
  border-radius: 999px;
  border: 1px solid #0089e5;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #66c1ff 0%,
    #33adff 50%,
    #0098ff 100%
  );
  box-shadow: 0px 1px 3px 0px #f5fbff inset;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.auth-form .form-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding-top: 24px;
  border-top: 1px solid #dbd9d7;
}
.form-links p {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.auth-form .form-links a {
  font-size: 12px;
  color: #000;
  text-decoration: none;
}

.auth-form .form-links a:nth-child(1) {
  margin-bottom: 3px;
}

.auth-form .form-links a p {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.auth-form .form-links .forget-btn {
  text-shadow: 0px 1px 2px black;
  text-decoration: underline;
  text-decoration-color: #fff;
}

.auth-form .form-links p:nth-child(1) a {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin-left: 8px;
}

@media screen and (max-width: 767px) {
  .auth-container {
    padding: 16px;
    max-width: 326px;
  }
  .auth-form h1 {
    font-size: 28px;
  }
  .auth-form form .join-btn {
    padding: 12px 8px;
  }
}
