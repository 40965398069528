.home-icon {
  width: 50px;
  height: 50px;
}
.custom-popup .card-for-property {
  align-items: stretch;
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 10px;
}
.custom-popup .card.card-for-property img {
  height: 70px;
  object-fit: cover;
  width: 70px !important;
  border-radius: 0px;
}
.right-property-wrapper .mapboxgl-popup .mapboxgl-popup-content {
  padding: 0px;
}
.my-property-section .card-for-property .card-heading1 {
  margin-bottom: 0px;
}
.my-property-section .card-for-property p {
  font-size: 14px;
  font-family: Inter;
}
.my-property-section .mapboxgl-popup {
  max-width: 300px !important;
}
@media screen and (max-width:480px){
    .my-property-section .mapboxgl-popup {
        max-width: 200px !important;
      }
}