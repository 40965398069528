*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.popup_inner {
  position: relative;
  background-color: #fff;
}
.popup_close {
  position: sticky;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0px 0px auto auto;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  z-index: 9;
}
.popup_content_wrapper {
    margin-top: -40px;
  }
  .fil0 {
    fill:black;
  }

  .popup_list_icon svg{
    width: 18px;
    height: 18px;
  }
.popup_close button{
  cursor: pointer;
  color: #717171;
  background-color: transparent;
  font-size: 28px;
  transform: rotate(45deg);
  line-height: 1;
  border: none;
}
.popup_inner_content{
  position: relative;
}
.popup-image {
  width: 100%;
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0px 20px 20px;
  position: relative;
}
.popup_location {
  width: 100%;
  margin-bottom: 5px;
}
.popup_location h3{
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.popup_location .loaction_white_icon{
  display: flex;
}
.popup_location .loaction_white_icon svg{ 
  width: 18px;
  height: 18px;
}
.popup_content {
  padding: 30px 20px;
}
.popup_place_title_retting {
  margin-bottom: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}
.popup_inner_details ul{
  display: flex;
  gap: 30px;
  list-style:disc;
  color: #fff;
  font-size: 16px;
}
.popup_inner_details ul li p{
  color: #fff;
  font-size: 16px;
}
.popup_detail h2{
  color: #000;
  font-size: 20px;
  margin-bottom: 25px;
}
.popup_inner_details ul li:first-child{
  list-style: none;
}
.popup_inner_details ul li:first-child p{

  color:#61abb6 ;
}
.popup_place_ratting{
    display: flex;
    align-items: center;
    gap: 7px;
}
.popup_place_ratting h4{
    font-size: 14px;
    font-weight: 700;
    color: #fff;
}
.popup_place_ratting .retting_star svg{
  width: 14px;
  height: 14px;
}
.popup_place_title h2 {
  color: #fff;
  font-size: 35px;
  font-weight: 700;
  line-height: 1;
}
.popup_lis_wrapper {
  list-style: none;
  padding: 0px;
}
.popup_lis_wrapper li {
    margin-bottom: 20px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
  }
  .popup_lis_wrapper li:last-child{
    border: none;
  }
.popup_lis_wrapper li h3{
    font-size: 17px;
    color: #000;
    margin-bottom: 10px;
    font-weight: 600;
    margin-bottom:8px;
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1;
  }
  .website_icon_text svg path{
    width: 18px;
    height: 18px;
  }
  .popup_lis_wrapper li h3 a{
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1;
    font-size: 17px;
    color: #000;
    text-decoration: none;
  }
  .popup_lis_wrapper li p {
    font-size: 14px;
    color: #000;
    margin-bottom: 4px;
    padding-left: 26px;
}
.popup_contact_number{
  font-size: 14px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.popup_list_open_now{
  color: #61abb6;
}
.type_tag{
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 500;
  margin: 5px 0px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.popup_lis_wrapper li:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.map-popup-component {
    position: fixed;
    top: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 50%;
    height: 50vh;
    z-index:2;
    border-radius: 30px 30px 0px 0px;
    transition: all 0.8s ease-in-out;
}
.map-popup-component.popup-show{
  top: 50vh;
}
span.popup_list_icon {
  display: flex;
  line-height: 1;
}
.map-popup-component::-webkit-scrollbar {
    display: none;
}
.popup-image:before {
  content: "";
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 21%, rgba(0,0,0,0.35057773109243695) 47%, rgba(0,0,0,0.8799894957983193) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.loaction_black_icon svg{
width: 18px;
height: 18px;
}
@media  screen and (max-width:991px) {
  .popup_inner_details ul li p{
    font-size: 14px;
  }
  .popup_location h3{
    font-size: 14px;
  }
  .popup_place_title h2{
    font-size: 25px;
  }
  .popup_lis_wrapper li h3{
    font-size: 15px;
  }
  .popup_lis_wrapper li h3 a{
    font-size: 15px;
  }
}
@media screen and (max-width:768px){
    .map-popup-component{
      width: 100%;
    }
  }
@media screen and (max-width:480px){
    .popup_content {
        padding: 25px 12px;
    }
    .popup_place_title h2 {
        font-size: 22px;
    }
  
    .popup_close {
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
    }
    .popup-image {
        height: 300px;
    }
    .popup_content_wrapper {
        margin-top: -30px;
      }
      .popup_close button{
        font-size: 24px;
      }
      .popup_inner_details ul{
        flex-direction: column;
        gap: 5px;
        list-style: none;
      }
}