.custom-modal-privacy-terms {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1000;
  }
  
  .modal-dialog-privacy-terms {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    height: 400px;
    width: 90%;
    position: absolute;
    left: 50%; 
    top: 50%;
    overflow: scroll;
    transform: translate(-50%, -50%);
}
  
  .modal-content-privacy-terms {
    padding: 20px;
    overflow-y: auto;
  }
  
  .modal-header-privacy-terms {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px 20px;
  }
  
  .modal-title-privacy-terms {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .close-privacy-terms {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 20px;
  }
  
  .modal-footer-privacy-terms {
    border-top: 1px solid #ccc;
    padding: 10px 20px;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #000;
}
  
  .btn {
    padding: 8px 16px;
    margin: 0 10px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }