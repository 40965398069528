/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 22, 2023 */



@font-face {
    font-family: 'nyght_serifbold';
    src: url('nyghtserif-bold-webfont.woff2') format('woff2'),
         url('nyghtserif-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nyght_serifbold_italic';
    src: url('nyghtserif-bolditalic-webfont.woff2') format('woff2'),
         url('nyghtserif-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nyght_serifdark';
    src: url('nyghtserif-dark-webfont.woff2') format('woff2'),
         url('nyghtserif-dark-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nyght_serifdark_italic';
    src: url('nyghtserif-darkitalic-webfont.woff2') format('woff2'),
         url('nyghtserif-darkitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nyght_seriflight';
    src: url('nyghtserif-light-webfont.woff2') format('woff2'),
         url('nyghtserif-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nyght_seriflight_italic';
    src: url('nyghtserif-lightitalic-webfont.woff2') format('woff2'),
         url('nyghtserif-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nyght_serifmedium';
    src: url('nyghtserif-medium-webfont.woff2') format('woff2'),
         url('nyghtserif-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nyght_serifmedium_italic';
    src: url('nyghtserif-mediumitalic-webfont.woff2') format('woff2'),
         url('nyghtserif-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nyght_serifregular';
    src: url('nyghtserif-regular-webfont.woff2') format('woff2'),
         url('nyghtserif-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'nyght_serifregular_italic';
    src: url('nyghtserif-regularitalic-webfont.woff2') format('woff2'),
         url('nyghtserif-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}