section.privacy-policy-section {
    padding: 40px 0;
}
.privacy-heading {
    color: #3b352b;
    font-family: "nyght_serifregular";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
}
.privacy-list {
    list-style: none;
}
.privacy-list li {
    margin-bottom: 25px;
}
.privacy-list li h4 {
    color: #3b352b;
    font-family: "nyght_serifregular";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.privacy-list li p {
    color: #675d4b;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}