.result-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  height: calc(100vh - 90px);
  overflow: hidden;
}

.left-result {
  border-radius: 24px 24px 0px 0px;
  background: rgba(0, 0, 0, 0.08);
  padding: 24px;
  overflow-y: auto;
}
.left-result:after {
  content: "";
  width: 100%;
  position: sticky;
  height: 150px;
  bottom: 40px;
  left: 0;
  display: inline-block;
  background: linear-gradient(0deg, #DED9CE 13.61%, rgba(222, 217, 206, 0.00) 100%);
  z-index: 0;
  /* margin-top: -100%; */
}
.map-container {
  height: 100vh;
  border-radius: 24px 24px 0px 0px;
}
.result-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-heading {
  color: #3b352b;
  font-family: 'nyght_serifregular';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heading-child {
  display: flex;
  align-items: center;
  gap: 16px;
}

span.result-tag {
  color: #3b352b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 999px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(0, 0, 0, 0.2);
  padding: 4px 8px;
}

button.find-btn {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 999px;
  border: 2px solid #3b352b;
  padding: 8px 16px;
  background: transparent;
}

.result-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.result-card {
  display: flex;
  gap: 16px;
  padding: 8px;
}

.result-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

span.circle-count {
  border-radius: 999px;
  background: #0098ff;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 3;
  width: 31px;
  height: 31px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.vertical-line {
  background: #0098ff;
  width: 2px;
  height: 100%;
}

h4.card-heading {
  color: #3b352b;
  font-family: 'nyght_serifregular';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  width: fit-content;
}

p.card-para {
  color: #675d4b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

button.open-btn {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: transparent;
  cursor: pointer;
  border: none;
  border-bottom: 2px solid #3b352b;
  /* z-index: 9; */
}

.result-card-button {
  display: flex;
  gap: 32px;
  align-items: center;
}

.result-card-txt {
  margin-bottom: 32px;
}
.card-img-wrapper img {
  border-radius: 16px;
  width: 140px;
  height: 140px;
  object-fit: cover;
}
.result-line-top {
  display: none;
}
.popup-title{
  font-size: 14px;
}
@media screen and (min-width: 991px) and (max-width:1280px){
  .card-img-wrapper img {
    width: 125px;
    height: 125px;
}
}
@media screen and (max-width: 991px) {
  .result-section .container-fluid {
    padding: 0px;
  }
  .result-card-txt {
    margin-bottom: 0px;
  }
  p.card-para{
    font-size: 14px;
  }
  .card-img-text-wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}
  p.card-para{
    margin-bottom: 0px;
  }
  .result-popup span.circle-count {
    position: absolute;
    left: 16px;
    top: 16px;
}
  .backdrop {
    display: none;
  }
  .result-section .popup {
    border-radius: 24px 24px 0px 0px;
    width: 100%;
    left: 0%;
    padding: 0px;
    height: -webkit-fill-available;
  }
  .result-section .popup-heading-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.08);
    margin-bottom: 0;
    padding: 16px;
    padding-left: 55px;
    position: relative;
}
  .result-section .popup-inner-content-wrapper {
    padding: 13px 16px 0px 16px;
  }
  .result-section .popup-footer{
    padding: 0 16px 100px;
  }
  .left-result {
    padding: 0px;
    transition: all 0.4s ease-in-out;
    background: #d5cec2;
    position: absolute;
    top: calc(100vh - 191px);
    z-index: 99999;
    height: -webkit-fill-available !important;
    max-height: calc(100vh - 160px);
    order: 2;
    overflow: scroll;
    width: 100%;
  }
  .result-line-top svg {
    width: 25px;
    fill: #3b352b;
}
  .result-wrapper {
    position: relative;
    height: calc(100vh - 65px);
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .active-class-for-mobile .left-result {
    top: calc(100vh - 95%);
    overflow: auto;
  }
  .result-heading-wrapper {
    margin-bottom: 0px;
  }
  .result-outer-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.08);
    padding: 16px;
    position: fixed;
    width: 100%;
    z-index: 1;
    background: #d5cec2;
    border-radius: 20px 20px 0px 0px;
}
  .result-card-wrapper {
    padding: 11px 16px 24px;
    padding-top: 173px;
    height: calc(100vh - 163px);
    overflow: scroll;
}
  .map-container {
    height: 100vh !important;
  }
  .result-line-top {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  h4.card-heading{
    font-size: 20px;
  }
  .result-line-top span {
    width: 60px;
    height: 2px;
    border-radius: 100px;
    background: var(--Label-Color-Light-Primary, #000);
  }
  .left-result:after {
    display: none;
  }
  .heading-child {
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
  }
  section.result-section {
    overflow: hidden;
    position: relative;
  }

  .mapboxgl-canvas {
    width: 100% !important;
  }

  .right-map-wrapper {
    order: 1;
  }
  .map-container {
    border-radius: 0px;
  }
  .result-card-button{
    gap: 68px;
  }
  .result-card{
    margin-bottom: 14px;
  }

}
@media screen and (max-width:767px){
  .left-result {
  top: calc(100vh - 196px);
    /* max-height: calc(100vh - 170px); */
  }
  .result-heading{
    font-size: 28px;
  }
  button.find-btn{
    font-size: 12px;
  }
  .result-card-wrapper {
    padding-top: 170px;
    height: calc(100vh - 160px);
}
  
}
@media screen and (max-width:480px){
  .card-img-wrapper img {
    width: 100px;
    height: 100px;
}
.left-result {
  top: calc(100vh - 240px);
  }
  .result-card{
    gap: 10px;
  }
  .result-card-button {
    gap: 32px;
}
.card-img-text-wrapper{
  margin-bottom: 8px;
}
  
}

