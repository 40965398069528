/* popup */
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(35, 31, 26, 0.80);
  backdrop-filter: blur(12px);
  transition: .5s all ease-in-out;
}

.hide {
  visibility: hidden;
  opacity: 0;
}
.img-fluid{
  width: 100%;
  height: auto;
}
.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 51px;
  height: 51px;
  font-size: 0;
  z-index: 9;
  cursor: pointer;
}

.close:before,
.close:after {
  content: "";
  position: absolute;
  background: #767676;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  -o-border-radius: 1px;
  border-radius: 1px;
  left: 14px;
  right: 14px;
  top: 30px;
  height: 3px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -webkit-transition: all 100ms ease-in;
  -o-transition: all 100ms ease-in;
}

.close:after {
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.popup {
  position: absolute;
  top: 0;
  left: 100%;
  width: 50%;
  z-index: 99;
  padding: 25px;
  color: #5e5e5e;
  height: 100%;
  transition: .5s all ease-in-out;
  border-radius: 24px 0px 0px 24px;
  border: 1px solid #E7E6E4;
  background: #F1EAE0;
  box-shadow: 0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.04), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.04), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.05), 0px 100px 80px 0px rgba(0, 0, 0, 0.07);
  overflow-y: auto;
}

.popup-body {
  padding: 0 0 25px 0;
}

body {
  overflow-x: hidden;
}

.popup-heading-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 30px;
}

.popup-title h4 {
  color: #3B352B;
  font-family: 'nyght_serifbold';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 6px;
}

.popup-title p {
  color: #675D4B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-image-warpper {
  margin-bottom: 35px;
}
.popup-image-warpper img {
  border-radius: 16px;
  max-height: 300px;
  object-fit: cover;
}

.dis-head h5 {
  color: #675D4B;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

p.dis-para {
  color: #675D4B;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dis-head {
  margin-bottom: 32px;
}

button.prev-btn {
  color: #605B52;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 7px;
}

button.prev-btn .circle {
  width: 27px;
  height: 27px;
  border-radius: 999px;
  background: #605B52;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  color: #fff;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* popup */
@media screen and (max-width:991px){
  .popup-image-warpper {
    margin-bottom: 21px;
}
  .popup-title h4{
    font-size: 20px;
  }
  .result-popup .popup-title h4 {
    padding-right: 21px;
}
  .popup-title{
    font-size: 14px;
  }
  .dis-head h5{
    font-size: 14px;
  }
  p.dis-para{
    font-size: 14px;
  }
  .popup-body{
    padding: 0px;
  }
  button.prev-btn{
    font-size: 0px;
  }
}