.vector-gradient-background {
  background: #323232;
  }
  /* This CSS selector may need to be adjusted based on your specific component's structure */
  .vector-gradient-background .amcharts-map-area {
    stroke: black !important;
  }
  div#vector-map-container {
    width: 100% !important;
    
    /* height: 1050px; */
    padding: 100px 90px 40px;
  }
  #vector-map-main {
    height: 900px;
    position: relative;
}
  .jvectormap-container {
    background: transparent !important;
  }
  .green-flag {
    background: #50c878;
    padding: 10px;
    color: white;
    border-radius: 5px;
  }
  
  .galaxy-blue-flag {
    background: #3f8fa4;
    padding: 10px;
    color: white;
    border-radius: 5px;
  }
  
  .tour-information {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  div#vector-map-container {
    width: 100% !important;
  }
  
  .map-parent .download-here {
    padding: 0px 90px 90px;
  }
  
  .map-parent .download-here button {
    background: #61abb6;
    padding: 12px 25px;
    border-radius: 20px;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 125%;
    color: #fff;
    border: none;
  }
  .map-logo {
    display: inline-block;
    right: 70px;
    position: absolute;
    top: 50px;
  }
  #overlay {
    position: fixed;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .cv-spinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px #ddd solid;
    border-top: 4px #2e93e6 solid;
    border-radius: 50%;
    animation: sp-anime 0.8s infinite linear;
  }
  /* map page */
  .map-parent:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000007d;
    z-index: -1;
  }
  .map-parent {
    background: url("./imgblue.jpeg") no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
  }
  div#vector-map-container {
    width: 100% !important;
    position: relative;
  }
  .my-visit-info {
    display: inline-block;
    position: absolute;
    bottom: 50px;
    left: 70px;
  }
  .my-visit-info h4 {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
  }
  .my-visit-info .visited-para {
    font-size: 16px;
    letter-spacing: 1.5px;
    color: #107de2;
  }
  .my-visit-info .wishlist-para {
    font-size: 16px;
    letter-spacing: 1.5px;
    color: #e27610;
  }
  
  @keyframes sp-anime {
    100% {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 768px) {
    div#vector-map-container {
      padding: 100px 40px 40px;
    }
    .map-logo {
      right: 40px;
      top: 40px;
  }
    .my-visit-info {
      bottom: 80px;
      left: 80px;
    }
    .my-visit-info h4 {
      font-size: 20px;
    }
    .my-visit-info .visited-para {
      font-size: 14px;
    }
    .my-visit-info .wishlist-para {
      font-size: 14px;
    }
    #vector-map-main {
      height: 700px;
      position: relative;
  }
  }
  @media (max-width: 500px) {
    #vector-map-main {
      height: 500px;
  }
    .logo-image {
      max-width: 60px;
    }
    div#vector-map-container {
      padding: 100px 20px 20px;
      
    }
    .map-logo {
      right: 20px;
      top: 20px;
  }
    .my-visit-info {
      bottom: 35px;
      left: 35px;
    }
    .my-visit-info h4 {
      font-size: 16px;
    }
    .my-visit-info .wishlist-para {
      font-size: 12px;
    }
    .my-visit-info .visited-para {
      font-size: 12px;
    }
  }
  
  .jvectormap-zoomin {
    display: block;
  }
  
  .jvectormap-zoomout {
    display: block;
  }
  