.my-trips-section .result-heading {
  color: #0098ff;
}
.my-trips-section .result-heading-wrapper {
  margin-bottom: 24px;
  padding: 0px 8px;
}
.my-trips-section span.result-tag {
  padding: 3px 15px;
  background: #0098ff;
  color: #f2ece2;
  font-weight: 600;
}
.my-trips-section .card-img-wrapper img {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.my-trips-section .left-result {
  background: #f2ece2;
}
.my-trips-section .result-card {
  padding-right: 50px;
  position: relative;
}
.trip-delete {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.trip-delete button {
  margin: 0px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #3b352b;
  background-color: transparent;
}
.my-trips-section .result-card-txt p {
  color: #3b352b;
  font-size: 12px;
  margin-bottom: 8px;
}
.my-trips-section h4.card-heading {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 8px;
  cursor: pointer;
}
.my-trips-section .result-card-txt p.card-para {
  font-weight: 700;
  margin-bottom: 0px;
}
.my-trips-section .trip-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-trips-section .result-card-txt {
  margin-bottom: 0px;
}
.property-black {
  margin-right: 8px;
}
/******************************* popup *****************************/
.delete-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.delete-shadow-overlay {
  background: rgba(59, 53, 43, 0.5);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.delete-popup-wrapper {
  padding: 16px;
  border-radius: 16px;
  background: #f7f6f3;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  max-width: 360px;
  width: 100%;
  position: relative;
}

.delete-close-button button {
  margin: 0px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #3b352b;
  position: absolute;
  right: 8px;
  top: 8px;
}
.delete-heading h2 {
  color: #0098ff;
  text-align: center;
  font-family: 'nyght_serifregular';
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 24px;
  line-height: 1.4;
}
.delete-msg-heading h2 {
  color: #0098ff;
  text-align: center;
  font-family: 'nyght_serifregular';
  font-size: 28px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 24px;
}
.delete-msg-logo {
  text-align: center;
}
.delete-subheading h4 {
  color: #3b352b;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}
.delete-trip-wrapper {
  display: flex;
  gap: 17px;
  margin-bottom: 16px;
}
.delete-trip-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}
.delete-trip-content p {
  color: #3b352b;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}
.delete-trip-content h3 {
  color: #3b352b;
  font-family: 'nyght_serifregular';
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 8px;
}
.delete-trip-content p.delete-location {
  font-weight: 700;
  margin-bottom: 0px;
}
.delete-red-button button {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 105%;
  border-radius: 999px;
  border: 1px solid #c74938;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #d88074 0%,
    #d26d60 50%,
    #c74938 100%
  );
  box-shadow: 0px 1px 3px 0px #f5fbff inset;
  padding: 12px;
  width: 100%;
  margin-bottom: 8px;
}
.delete-cancel-button button {
  color: #3b352b;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 105%;
  padding: 4px;
  width: 100%;
  border: none;
  background-color: transparent;
}
/***************************** Header Dropdown  ************************/
.header-dropdown a {
  text-decoration: none;
  color: #000000;
}

.header-dropdown a:hover {
  color: #222222;
}

.header-dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-image {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin-right: 8px;
}
.header-dd-button {
  display: flex;
  flex-direction: column;
  border-radius: 21px;
  background: #fff;
  cursor: pointer;
  white-space: nowrap;
  color: #3b352b;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  padding: 4px;
  cursor: pointer;
}

.dropdown-arrow {
  margin-left: 16px;
  pointer-events: none;
}

.header-dd-input {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0px;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.header-inner-button {
  display: flex;
  align-items: center;
}
.header-dd-menu {
  padding: 8px 0px 4px 0px;
  list-style-type: none;
  position: absolute;
  top: 40px;
  left: 0px;
  background: #fff;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
  z-index: 999;
}

.header-dd-input ~ .header-dd-menu {
  display: none;
}

.header-dd-input:checked ~ .header-dd-menu {
  display: block;
}

.header-dd-input:checked ~ .header-dd-button {
  border-radius: 20px 20px 0px 0px;
}
.header-dd-input
  ~ .header-dd-button
  > .header-inner-button
  > .dropdown-arrow
  > svg {
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}
.header-dd-input:checked
  ~ .header-dd-button
  > .header-inner-button
  > .dropdown-arrow
  > svg {
  transform: rotate(180deg);
}
.header-dd-menu li {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
}
.header-dd-menu li.profile {
  color: #3b352b;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.header-dd-menu li.trip {
  color: #0098ff;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  border-top: 1px solid #dbd9d7;
  border-bottom: 1px solid #dbd9d7;
}
.header-dd-menu li .logout {
  color: #868179;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.header-dd-menu li.logout{
  color: #868179;
}
.no-result-found-on-mytrips {
  text-align: center;
  width: 100%;
  height: calc(100vh - 150px);
  position: relative;
}
.no-result-found-on-mytrips h2{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 991px) {
  .header-dd-menu li.trip {
    color: #000;
  }
  .header-dd-menu li.trip svg path{
    stroke: #000;
  }
  .my-trips-section .left-result {
    width: 100%;
    padding: 16px 20px 16px 8px;
    border-radius: 0px;
    max-height: -webkit-fill-available;
    min-height: 100%;
    overflow: auto;
    top: 0px;
  }

  .my-trips-section .heading-child {
    align-items: center;
    gap: 8px;
    flex-direction: row;
  }
  .my-trips-section .result-heading-wrapper {
    margin-bottom: 19px;
    padding: 0px 0px 0px 8px;
  }
  .my-trips-section h4.card-heading {
    font-size: 21px;
  }
}
@media screen and (max-width: 767px) {
  .my-trips-section .result-heading {
    font-size: 32px;
  }
}
@media screen and (max-width: 480px) {
  .delete-popup-wrapper {
    max-width: 326px;
  }
}
