@import url("https://fonts.googleapis.com/css2?family=Creepster&family=Inter:wght@300;400;500;600;700&display=swap");
@import url("./webfontkit-20231222-044706/stylesheet.css");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Inter;
  margin: 0;
  background-color: #f2ede2;
}
.triptick-main-class {
  position: relative;
  overflow: hidden;
}
.container-fluid {
  width: 100%;
  padding: 0 40px;
}
a {
  text-decoration: none;
}
/* header styling */
.brand-logo svg {
  cursor: pointer;
}
.navber {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  align-items: center;
}
.nav-menu {
  display: flex;
  gap: 30px;
  align-items: center;
}
a.login-btn {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
a.sign-up-btn {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 999px;
  border: 2px solid #3b352b;
  padding: 8px 16px;
}
.property-btn {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 999px;
  padding: 8px 16px;
}
/* header styling end */

/* hero section styling */
.hero-wrapper {
  height: calc(100vh - 105px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}
.hero-heading {
  color: #3b352b;
  font-family: "nyght_serifregular";
  font-size: 4.63vw;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
}
.hero-heading span {
  font-style: italic;
}
.hero-video-card-1 video {
  border-radius: 999px 0px 0px 999px;
  object-fit: cover;
  width: 100%;
  height: auto;
  margin-right: -40px;
  pointer-events: none;
}
.hero-video-card-1 .video-wrapper {
  justify-content: flex-end;
}
.hero-video-card-1 {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.hero-txt-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.hero-video-card-2 video {
  border-radius: 0px 999px 999px 0px;
  object-fit: cover;
  margin-left: -40px;
  width: 100%;
  height: auto;
  pointer-events: none;
}
.text-card-1 svg {
  position: absolute;
  right: 0;
  max-height: 75%;
  top: 0;
}
.hero-video-card-1 svg {
  position: absolute;
  bottom: 0;
  transform: rotate(180deg);
  max-width: 65%;
}
.hero-video-card-2 svg {
  position: absolute;
  top: 0;
  max-width: 65%;
}
.hero-video-card-2 {
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.text-card-2 svg {
  position: absolute;
  left: -2px;
  transform: rotate(180deg);
  max-height: 75%;
  bottom: 0;
}
.video-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  width: 28vw;
}
/* search bar styling */
.hero-form-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  height: fit-content;
  display: flex;
  justify-content: center;
}
.form-content {
  border-radius: 999px;
  background: #eeece7;
  box-shadow: 0px 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  max-width: 32.935vw;
  padding: 8px 8px 8px 12px;
  gap: 10px;
  width: 100%;
}
.search-input {
  width: 100%;
  background: transparent;
  border: none;
  color: #868179;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.search-btn {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 105%;
  border-radius: 999px;
  border: 1px solid #0089e5;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #66c1ff 0%,
    #33adff 50%,
    #0098ff 100%
  );
  box-shadow: 0px 1px 3px 0px #f5fbff inset;
  padding: 8px 12px;
}

.search-btn-disabled {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 105%;
  border-radius: 999px;
  border: 1px solid #0089e5;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #999999 0%,
    #979797 50%,
    #a5a5a5 100%
  );
  box-shadow: 0px 1px 3px 0px #f5fbff inset;
  padding: 8px 12px;
  white-space: nowrap;
}
.search-input:focus-visible {
  outline: none;
}
.search-suggetion {
  position: absolute;
  top: 125%;
  /* max-width: 28.935vw; */
}
span.black-txt {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 105%;
}
span.white-txt-right {
  color: #94856b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  border-radius: 50px 48px 48px 0px;
  background: #fff;
  padding: 4px 8px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 47%,
    rgba(255, 255, 255, 0.7231267507002801) 74%,
    rgba(3, 177, 209, 0) 100%
  );
}
span.white-txt-left {
  border-radius: 50px 48px 48px 0px;
  color: #94856b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  padding: 4px 8px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 47%,
    rgba(255, 255, 255, 0.7231267507002801) 74%,
    rgba(3, 177, 209, 0) 100%
  );
}
.plus-btn {
  border-radius: 999px;
  border: 2px solid rgba(59, 53, 43, 0.25);
  background: #3b352b;
  line-height: 0;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
  padding: 4px;
}
.suggetion-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 7px;
  justify-content: center;
}
.cities {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 28.935vw;
  top: 100%;
  /* background: #fff; */
  border-radius: 12px;
  margin-top: 15px;
  /* box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 10px 12px; */
  transition: height 0.5s ease-in-out;
  z-index: 9;
}
.cities-child {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 10px 12px;
  background: #fff;
  border-radius: 12px;
}
.cities ul .country-name {
  background: #0089e5;
  border-radius: 10px 10px 10px 0px;
  padding: 3px 10px;
  color: #fff;
  line-height: 1.4em;
  box-shadow: 0px 1px 3px 0px #f5fbff inset;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
}
.cities ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* loader styling */
.loader-heading {
  text-align: center;
}
.loader-heading h1 {
  margin-bottom: 30px;
  font-family: "nyght_serifregular";
  color: #0098ff;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.nav-menu .sign-up-btn {
  color: #3b352b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: transparent;
  border-radius: 999px;
  border: 2px solid #3b352b;
  display: inline-flex;
}
.cities h5 {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
}
.form-content-inner {
  width: 100%;
  max-width: 28.935vw;
  margin: auto;
}
.form-content > div {
  width: 100%;
}
.user-suggest-txt-wrapper {
  border-top: 1px solid #ccc;
  margin-top: 15px;
  padding-top: 15px;
}
.user-suggest-txt-wrapper p {
  font-size: 13px;
  margin-bottom: 15px;
}
.user-suggest-txt-wrapper textarea {
  min-height: 60px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 10px;
}
/* result page searchbar */
.for-result-form .search-btn {
  font-size: 13px;
  padding: 6px 10px;
}
.form-content.for-result-form .search-input {
  font-size: 13px;
  min-width: 243px;
}
.form-content.for-result-form {
  padding: 5px 7px 5px 10px;
  box-shadow: 0px 0px 3px 6px rgba(0, 0, 0, 0.1);
  max-width: 40.935vw;
}
button {
  cursor: pointer;
}
.loader-parent {
  padding: 50px 0 87px;
  position: relative;
}
.loader-parent svg {
  max-height: 60px;
}
svg.top-line {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  max-height: 50px !important;
}
svg.right-line {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  margin: auto;
  transform: rotate(90deg);
}
svg.bottom-line {
  position: absolute;
  bottom: 40px;
  right: 0;
  left: 0;
  margin: auto;
  transform: rotate(180deg);
}
svg.left-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  margin: auto;
  transform: rotate(270deg);
}
.form-content.searchbar-active {
  outline: #0098ff solid 1px;
  background: #fff;
}
.hero-form-right-svg {
  display: none;
}
.hero-form-left-svg {
  display: none;
}
.hero-form-top-svg {
  display: none;
}
.hero-form-bottom-svg {
  display: none;
}

/***************************** Header Dropdown  ************************/
.property-black {
  margin-right: 8px;
}
.header-dropdown a {
  text-decoration: none;
  color: #000000;
}

.header-dropdown a:hover {
  color: #222222;
}

.header-dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-image {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin-right: 8px;
}
.header-dd-button {
  display: flex;
  flex-direction: column;
  border-radius: 999px;
  background: #fff;
  cursor: pointer;
  white-space: nowrap;
  color: #3b352b;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  padding: 4px;
  cursor: pointer;
}

.dropdown-arrow {
  margin-left: 16px;
  pointer-events: none;
}

.header-dd-input {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0px;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.header-inner-button {
  display: flex;
  align-items: center;
}
.header-dd-menu {
  padding: 8px 0px 4px 0px;
  list-style-type: none;
  position: absolute;
  top: 40px;
  left: 0px;
  background: #fff;
  width: 100%;
  border-radius: 0px 0px 16px 16px;
  z-index: 9;
}

.header-dd-input ~ .header-dd-menu {
  display: none;
}

.header-dd-input:checked > .header-dd-menu {
  display: block;
}
.header-dd-input:checked ~ .header-dd-button {
  border-radius: 16px 16px 0px 0px;
}
.header-dd-input
  ~ .header-dd-button
  > .header-inner-button
  > .dropdown-arrow
  > svg {
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}
.header-dd-input:checked
  ~ .header-dd-button
  > .header-inner-button
  > .dropdown-arrow
  > svg {
  transform: rotate(180deg);
}
.header-dd-menu li {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
}
.header-dd-menu li.profile {
  color: #3b352b;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.header-dd-menu li.trip-black {
  color: #3b352b;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border-top: 1px solid #dbd9d7;
  border-bottom: 1px solid #dbd9d7;
}
.header-dd-menu li .logout {
  color: #868179;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.property-dw {
  display: none !important;
}
.brand-logo {
  display: flex;
  align-items: center;
}
/******************************* Responsive ************************/
@media screen and (max-height:900px){
  .cities-child {
    height: 220px;
    overflow: auto;
  }
}
@media screen and (max-width: 991px) and (min-width: 767px) {
  .form-content-inner {
    max-width: 40.935vw;
  }
  .form-content {
    max-width: 40.935vw;
  }
  .cities {
    max-width: 40.935vw;
  }
  .form-content.for-result-form {
    padding: 3px 7px 3px 10px;
  }
}
@media screen and (max-width: 991px) {
  header {
    background: #f2ece2;
    box-shadow: 0px 4px 8px 0px #e4dccf;
    position: relative;
    z-index: 9;
  }
  .search-btn-disabled {
    font-size: 10px;
    padding: 8px 8px;
  }
  .form-content.for-result-form .search-input {
    font-size: 10px;
    min-width: 156px;
  }
  .header-inner-button {
    font-size: 0px;
  }
  .dropdown-arrow {
    margin-left: 0px;
  }
  .header-dd-input:checked ~ .header-dd-button > .header-inner-button {
    font-size: 14px;
  }
  .header-dd-input:checked
    ~ .header-dd-button
    > .header-inner-button
    > .dropdown-arrow {
    margin-left: 16px;
  }
  .navber {
    padding: 16px 0;
  }
  /* .property-btn {
    display: none !important;
  } */
  .property-dw {
    color: #3b352b;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    display: flex !important;
    border-bottom: 1px solid #dbd9d7;
  }
}
@media screen and (max-width: 767px) {
  .hero-video-card-1 svg {
    display: none;
  }
  .hero-video-card-2 svg {
    display: none;
  }
  .text-card-1 svg {
    display: none;
  }
  .text-card-2 svg {
    display: none;
  }

  .hero-form-right-svg {
    display: flex;
    position: absolute;
    right: 0px;
    left: auto;
    max-width: 12vw;
  }
  .hero-form-right-svg svg {
    width: 100%;
  }
  .hero-form-left-svg svg {
    width: 100%;
  }

  .hero-form-left-svg {
    display: flex;
    position: absolute;
    left: 0px;
    max-width: 12vw;
  }
  .hero-form-top-svg {
    display: flex;
    position: absolute;
    left: 50%;
    bottom: 50px;
  }
  .search-suggetion .hero-form-bottom-svg {
    top: auto;
  }
  .hero-form-bottom-svg {
    display: flex;
    position: absolute;
    left: 50%;
    top: 53px;
  }
  .hero-form-wrapper {
    align-items: center;
  }
  .form-content-inner {
    max-width: 60.935vw;
  }
  .form-content {
    max-width: 60.935vw;
  }
  .cities {
    max-width: 60.935vw;
  }
  .hero-wrapper {
    grid-template-columns: 1fr;
    justify-items: center;
    height: calc(100vh - 121px);
  }
  .text-card-1 {
    order: 2;
    margin-bottom: 80px;
  }
  .text-card-1 {
    order: 2;
    margin-bottom: 80px;
    grid-row: span 9;
    align-items: flex-start;
  }
  .hero-video-card-2 {
    order: 4;
  }
  .text-card-2 {
    order: 3;
    margin-top: 80px;
    grid-row: span 9;
    align-items: flex-end;
  }
  .hero-heading {
    font-size: 40px;
  }
  .hero-video-card-1 video {
    border-radius: 0px 0px 999px 999px;
    height: 140px;
    margin-right: 0px;
  }
  .hero-video-card-2 video {
    border-radius: 999px 999px 0px 0px;
    margin-left: 0px;
    height: 140px;
  }
  .suggetion-wrapper {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  .search-suggetion {
    width: 100%;
    max-width: 54.935vw;
    background: #f2ede2;
    z-index: 9;
    padding: 10px;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 4px 10px -6px #2f2f2f;
  }

  .cities {
    z-index: 99;
  }
  .video-wrapper {
    width: 33vw;
  }
  a.login-btn {
    color: #000;
    padding: 12px 20px;
    border-radius: 999px;
    border: 2px solid #000;
  }
  .nav-menu .sign-up-btn {
    color: #000;
    padding: 12px 20px;
    border-radius: 999px;
    border: 2px solid #000;
  }

  .container-fluid {
    padding: 0px 16px;
  }

  .hero-video-card-1 .video-wrapper {
    align-items: flex-start;
  }
  .hero-video-card-2 .video-wrapper {
    align-items: flex-end;
  }
  .loader-parent {
    padding: 82px 0 87px;
    overflow: hidden;
    height: 90vh;
  }
  svg.top-line {
    display: none;
  }
  svg.right-line {
    display: none;
  }
  svg.bottom-line {
    display: none;
  }
  svg.left-line {
    display: none;
  }
  .loading-search-bar {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) !important;
    top: 96px;
    z-index: 9;
    width: 100%;
    padding: 0px 20px;
  }
  .form-content.for-result-form {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .search-input {
    font-size: 14px;
  }
  .form-content-inner {
    max-width: 80.935vw;
  }
  .form-content {
    max-width: 80.935vw;
  }
  .cities {
    max-width: 80.935vw;
  }
  .hero-form-right-svg {
    right: -10px;
  }
  .hero-form-left-svg {
    left: -10px;
  }
  .suggetion-wrapper span {
    display: inline-block;
  }
  .video-wrapper {
    width: 45vw;
  }
  .nav-menu .sign-up-btn {
    padding: 6px 15px;
  }
  a.login-btn {
    padding: 6px 15px;
  }
  .nav-menu {
    gap: 8px;
  }
  .search-suggetion {
    width: 100%;
    max-width: 70.935vw;
  }
  .form-content.for-result-form {
    width: 100%;
    max-width: 100%;
  }
  .brand-logo svg {
    width: 70px;
}
.property-btn {
  padding: 8px 3px 8px 10px;
}

}
