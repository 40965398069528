.map-container {
  height: 100vh;
}

.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}
.map-marker-popup img{
  width: 210px !important;
  height: 150px;
  object-fit: cover;

}
.mapboxgl-popup .mapboxgl-popup-close-button {
  font-size: 16px;
  line-height: 1;
  right: 10px;
  top: 10px;
}
.mapboxgl-popup-close-button:focus-visible {
  outline: none;
}
.mapboxgl-popup-close-button:hover {
  background-color: transparent !important;
}
.map-marker-popup h3{
  margin-bottom: 10px;
}
.mapboxgl-popup .mapboxgl-popup-content {
  border-radius: 10px;
  padding: 15px 15px 15px;
}

.mapboxgl-popup .mapboxgl-popup-content {
  box-shadow: 0 1px 1rem -4px #000;
}
.map-marker-wrapper {
  background: url(/src/assets/images/map-icon-bg.svg) no-repeat center center;
  width: 40px;
  height: 50px;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-marker-wrapper img {
  width: 20px;
  height: 24px;
  margin-top: -7px;
  filter: invert(1);
}
.mapboxgl-ctrl-top-right {
  right: 10px !important;
  top: auto !important;
  bottom: 110px !important;
}
.mapboxgl-ctrl-group {
  background: transparent !important;
  border-radius: 15px !important;
  border: 2px solid #000;
  box-shadow: none !important;
}
.mapboxgl-ctrl-group button+button {
  border-top: none !important;
}
.mapboxgl-ctrl button .mapboxgl-ctrl-icon{
  filter: brightness(0);
  }
  .mapboxgl-ctrl-compass{
    display: none !important;
  }
  .mapboxgl-ctrl-group button {
    width: 28px !important;
    height: 28px !important;
  }


@media (max-width: 768px) {
  .map-container {
    height: 50vh;
  }
}
