
.property-blue {
  margin-right: 8px;
}
.result-section.my-property-section .left-result {
  background-color: #f2ede2;
}
.my-property-section .result-heading {
  color: #0098ff;
}
.my-property-section .result-heading-wrapper {
  margin-bottom: 40px;
}
.my-property-section .property-location-name {
  color: #3b352b;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}
.my-property-section .location-container {
  padding-top: 7px;
  margin-bottom: 22px;
  overflow: hidden;
}
.my-property-section .property-location-name span {
  color: #f2ece2;
  font-size: 12px;
  font-weight: 600;
  width: 32px;
  height: 23px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: #3b352b;
  margin-left: 8px;
}
/* .my-property-section .card {
  width: 300px;
} */
.my-property-section .card img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 8px;
}
.my-property-section .card-left p {
  color: #3b352b;
  font-size: 12px;
  font-weight: 700;
}
.my-property-section .card-left-icon {
  margin-right: 4px;
}
.my-property-section .card-image {
  display: flex;
  position: relative;
  margin-bottom: 8px;
  border-radius: 8px;
}
.my-property-section .view-more-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
.my-property-section .card-image:hover .view-more-button {
  opacity: 1;
}
.my-property-section .view-more-button a {
  padding: 8px 16px;
  color: #f2ece2;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  border-radius: 999px;
  border: 2px solid #3b352b;
  background: #3b352b;
  display: flex;
  align-items: center;
  gap: 8px;
}
.my-property-section .view-more-button span.right-arrow {
  display: inline-flex;
}
.my-property-section .card-image-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: 0;
  background: #3b352b;
  transition: all 0.4s ease-in-out;
}
.my-property-section .card-image:hover .card-image-overlay {
  opacity: 0.5;
}
.property-list-slider .slick-list {
  margin: 0px -12px;
}
.property-list-slider .slick-slide {
  margin: 0px 12px;
}
.property-list-slider .slick-dots {
  display: none !important;
}
.my-property-section .card-heading1 {
  margin-bottom: 5px;
}
.my-property-section .slick-track {
  display: flex;
}
.my-property-section .card-two {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
}
.my-property-section .card-two .card {
  width: calc(50% - 30px);
}
.my-property-section .slick-arrow {
  width: 32px;
  height: 32px;
}
.my-property-section .slick-arrow.slick-next {
  top: -30px;
  right: 0px;
}
.my-property-section .slick-arrow.slick-prev {
  top: -30px;
  left: auto;
  right: 45px;
}
.slick-next:before {
  content: "";
  background-image: url(../../assets/images/button-next.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: inline-block;
  width: 32px;
  height: 32px;
}
.slick-prev:before {
  content: "";
  background-image: url(../../assets/images/button-prev.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: inline-block;
  width: 32px;
  height: 32px;
}
.my-property-section .slick-slide {
  width: 300px !important;
}
.property .result-heading-wrapper {
  display: none;
}
.property-blue-link {
  color: #0098ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 999px;
  padding: 8px 16px;
}
.property-blue-dw {
  display: none !important;
}
.custom-popup .card-for-property{
  align-items: stretch;
  display: flex;
  gap: 10px;
  margin: 0;
}
@media screen and (max-width: 1280px) {
  .my-property-section .card-two {
    gap: 20px;
  }
  .my-property-section .card-two .card {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .property-blue-dw {
    display: flex !important;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    border-bottom: 1px solid #dbd9d7;
  }
  .property-blue-dw svg path {
    stroke: #000;
  }
  /* .property-blue-link {
    display: none;
  } */
  .header-inner-button {
    font-size: 0px;
  }
  .dropdown-arrow {
    margin-left: 0px;
  }
  .header-dd-input:checked ~ .header-dd-button > .header-inner-button {
    font-size: 14px;
  }
  .header-dd-input:checked
    ~ .header-dd-button
    > .header-inner-button
    > .dropdown-arrow {
    margin-left: 16px;
  }
  .property .sign-up-btn.property-blue-link {
    display: none;
  }
  .my-property-section .result-heading-wrapper {
    display: none;
  }
  .property .result-heading-wrapper {
    display: block;
    width: 100%;
    padding-top: 26px;
  }
  .property .navber {
    flex-wrap: wrap;
    padding-bottom: 10px;
  }
  .property .result-heading {
    color: #0098ff;
    font-family: 'nyght_serifregular';
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
  }
  .my-property-section .left-result {
    width: 100%;
    padding: 16px;
    padding-top: 20px;
    border-radius: 0px;
    max-height: -webkit-fill-available;
    min-height: 100%;
    overflow: auto;
    top: 0px;
  }
  .result-heading-wrapper {
    padding: 10px 0px;
  }

.my-property-section .card-two {
  gap: 40px;
}
.my-property-section .card-two .card {
  width: calc(50% - 20px);
}
}
@media screen and (max-width:767px){
.my-property-section .card-two {
  gap: 20px;
}
.my-property-section .card-two .card {
  width: 100%;
}
}
@media(max-width:480px){
  .property-blue-link {
    padding: 8px 3px 10px 10px;
}
}